var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enable()
    ? _c("el-alert", {
        attrs: {
          title: "【" + _vm.title + "】文档地址：" + _vm.url,
          type: "success",
          "show-icon": "",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }